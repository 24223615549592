<template>
    <div class="md-layout-item md-size-50 md-large-size-100 md-small-size-100">
        <div class="md-layout md-size-100 md-alignment-center-right">
            <InspectionLink :asset-id="bid" :inspection-type="'inventory'" :asset-type="'bridge'"/>
        </div>
        <form>
            <div :style="{'padding-left':0, 'padding-right':0}" class="">
                <md-card>
                    <md-card-header>
                        <div class="md-headline card-title"> {{ $t("bridges.structural_features_and_elements") | UpCase }}</div>

                    </md-card-header>
                    <md-card-content>
                        <md-field>
                            <label>
                                {{ $t(`bridges.type_of_bridge`) }}
                            </label>
                            <md-select
                                    v-model="features.type_of_bridge">
                                <md-option v-for="type in bridgeTypes" :value="type" :key="type">{{
                                    $t(`bridges.${type}`)
                                    }}
                                </md-option>
                            </md-select>
                        </md-field>
                        <div>
                            <md-datepicker
                                    v-model="features.inspection_date"
                                    name="dateOfInspector"
                                    md-immediately>
                                <label>
                                    {{ $t(`bridges.inspection_date`) }}
                                </label>
                            </md-datepicker>

                        </div>
                        <md-field>
                            <label>
                                {{ $t(`bridges.structural_form`) }}
                            </label>
                            <md-select
                                    v-model="features.structural_form">
                                <md-option v-for="type in bridgeStructuralForms" :value="type" :key="type">{{
                                    $t(`bridges.${type}`)
                                    }}
                                </md-option>
                            </md-select>
                        </md-field>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.deck_type`) }}
                                    </label>
                                    <md-select
                                            v-model="features.deck_type"
                                            @md-closed="deckTypeChanged">
                                        <md-option v-for="type in deckTypes" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.deck_material`) }}
                                    </label>
                                    <md-select
                                            v-model="features.deck_material"
                                            :disabled="deckTypeMaterials.length===1000"
                                    >
                                        <md-option v-for="type in deckTypeMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>
                        <md-field>
                            <label>
                                {{ $t(`bridges.edge_beam`) }}
                            </label>
                            <md-select
                                    v-model="features.edge_beam">
                                <md-option v-for="type in edgeBeamMaterials" :value="type" :key="type">{{
                                    $t(`bridges.${type}`)
                                    }}
                                </md-option>
                            </md-select>
                        </md-field>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.main_girder_type`) }}
                                    </label>
                                    <md-select
                                            v-model="features.main_girder_type"
                                            @md-closed="mainGirderTypeChanged">
                                        <md-option v-for="type in mainGirgerTypes" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.main_girder_material`) }}
                                    </label>
                                    <md-select
                                            v-model="features.main_girder_material"
                                            :disabled="mainGirderMaterials.length===0"
                                    >
                                        <md-option v-for="type in mainGirderMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.cross_beam_type`) }}
                                    </label>
                                    <md-select
                                            v-model="features.cross_beam_type"
                                            @md-closed="crossBeamTypeChanged">
                                        <md-option v-for="type in crossBeamTypes" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.cross_beam_material`) }}
                                    </label>
                                    <md-select
                                            v-model="features.cross_beam_material"
                                            :disabled="crossBeamMaterials.length===0"
                                    >
                                        <md-option v-for="type in crossBeamMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>
                        <md-field>
                            <label>
                                {{ $t(`bridges.construction_joints`) }}
                            </label>
                            <md-select
                                    v-model="features.construction_joints">
                                <md-option v-for="type in constructionJointsList" :value="type" :key="type">{{
                                    $t(`bridges.${type}`)
                                    }}
                                </md-option>
                            </md-select>
                        </md-field>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.abutment_type`) }}
                                    </label>
                                    <md-select
                                            v-model="features.abutment_type"
                                            @md-closed="abutmentsTypeChanged">
                                        <md-option v-for="type in abutmentsTypes" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.abutment_material`) }}
                                    </label>
                                    <md-select
                                            v-model="features.abutment_material"
                                            :disabled="abutmentsMaterials.length===0"
                                    >
                                        <!--md-option value="">{{ $t(`bridges.none`) }}</md-option-->
                                        <md-option v-for="type in abutmentsMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>
                        <md-field>
                            <label>
                                {{ $t(`bridges.abutment_cap`) }}
                            </label>
                            <md-select
                                    v-model="features.abutment_cap">
                                <md-option value="">{{ $t(`bridges.none`) }}</md-option>
                                <md-option v-for="type in abutmentCapMaterials" :value="type" :key="type">{{
                                    $t(`bridges.${type}`)
                                    }}
                                </md-option>
                            </md-select>
                        </md-field>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.wing_walls_type`) }}
                                    </label>
                                    <md-select
                                            v-model="features.wing_walls_type"
                                            @md-closed="wingWallChanged">
                                        <md-option v-for="type in wingWallsTypes" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.wing_walls_material`) }}
                                    </label>
                                    <md-select
                                            v-model="features.wing_walls_material"
                                            :disabled="wingWallsMaterials.length===0"
                                    >
                                        <!--md-option value="">{{ $t(`bridges.none`) }}</md-option-->
                                        <md-option v-for="type in wingWallsMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.pier`) }}
                                    </label>
                                    <md-select
                                            v-model="features.pier"
                                            @md-closed="piersChanged">
                                        <md-option v-for="type in piersTypes" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.pier_material`) }}
                                    </label>
                                    <md-select
                                            v-model="features.pier_material"
                                            :disabled="piersMaterials.length===0"
                                    >
                                        <!--md-option value="">{{ $t(`bridges.none`) }}</md-option-->
                                        <md-option v-for="type in piersMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>
                        <md-field>
                            <label>
                                {{ $t(`bridges.pier_cap`) }}
                            </label>
                            <md-select
                                    v-model="features.pier_cap">
                                <md-option value="">{{ $t(`bridges.none`) }}</md-option>
                                <md-option v-for="type in piersCapMaterials" :value="type" :key="type">{{
                                    $t(`bridges.${type}`)
                                    }}
                                </md-option>
                            </md-select>
                        </md-field>
                        <md-field>
                            <label>
                                {{ $t(`bridges.bearing1`) }}
                            </label>
                            <md-select
                                    v-model="features.bearing1">
                                <md-option v-for="type in bearings" :value="type" :key="type">{{
                                    $t(`bridges.${type}`)
                                    }}
                                </md-option>
                            </md-select>
                        </md-field>
                        <md-field>
                            <label>
                                {{ $t(`bridges.bearing2`) }}
                            </label>
                            <md-select
                                    v-model="features.bearing2">

                                <md-option v-for="type in bearings" :value="type" :key="type">{{
                                    $t(`bridges.${type}`)
                                    }}
                                </md-option>
                            </md-select>
                        </md-field>
                        <md-switch v-model="features.bearing_seat" class="md-success" value="true">
                            {{ $t("bridges.bearing_seat") }}
                        </md-switch>
                        <md-field>
                            <label>
                                {{ $t(`bridges.expansion_joints`) }}
                            </label>
                            <md-select
                                    v-model="features.expansion_joints">
                                <md-option value="">{{ $t(`bridges.none`) }}</md-option>
                                <md-option v-for="type in expansionJoints" :value="type" :key="type">{{
                                    $t(`bridges.${type}`)
                                    }}
                                </md-option>
                            </md-select>
                        </md-field>
                        <span class="md-title category-header">{{ $t("bridges.pipes") }}</span>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.outlet_pipes`) }}
                                    </label>
                                    <md-select v-model="features.outlet_pipes">
                                        <md-option value="">{{ $t(`bridges.none`) }}</md-option>
                                        <md-option v-for="type in outletPipeMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.downsprout_pipes`) }}
                                    </label>
                                    <md-select v-model="features.downsprout_pipes">
                                        <md-option value="">{{ $t(`bridges.none`) }}</md-option>
                                        <md-option v-for="type in downsproutPipeMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.deck_drains`) }}
                                    </label>
                                    <md-select v-model="features.deck_drains">
                                        <md-option value="">{{ $t(`bridges.none`) }}</md-option>
                                        <md-option v-for="type in deckDrainMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.pipe`) }}
                                    </label>
                                    <md-select v-model="features.pipe">
                                        <md-option value="">{{ $t(`bridges.none`) }}</md-option>
                                        <md-option v-for="type in pipeMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>

                        <span class="md-title category-header">{{ $t("bridges.approach_way") }}</span>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.approach_way_cover`) }}
                                    </label>
                                    <md-select
                                            v-model="features.run_on_slab_cover">
                                        <md-option value="">{{ $t(`bridges.none`) }}</md-option>
                                        <md-option v-for="type in runOnSlabCovers" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.approach_way_kerbs`) }}
                                    </label>
                                    <md-select v-model="features.run_on_slab_kerbs">
                                        <md-option v-for="type in runOnSlabKerbsMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.approach_way_drainage_gully`) }}
                                    </label>
                                    <md-select v-model="features.run_on_slab_drainage_gully">
                                        <md-option v-for="type in runOnSlabDrainageGullyMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.approach_way_vehicle_restraint_system`) }}
                                    </label>
                                    <md-select v-model="features.run_on_slab_vehicle_restraint_system">
                                        <md-option v-for="type in runOnSlabVehicleRestraintSystemMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>

                        <span class="md-title category-header">{{ $t("bridges.bridge_surface") }}</span>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.road`) }}
                                    </label>
                                    <md-select v-model="features.pavement_road">
                                        <md-option v-for="type in pavements_overlay_road" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.sidewalk`) }}
                                    </label>
                                    <md-select v-model="features.pavement_sidewalk">
                                        <md-option v-for="type in pavements_overlay_sidewalk" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>
                        <span class="md-title category-header">{{ $t("bridges.barrier_and_wind_screens") }}</span>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.barrier_vehicle_restraint_system`) }}
                                    </label>
                                    <md-select v-model="features.barrier_vehicle_restraint_system">
                                        <md-option v-for="type in barrierVehicleRestraintSystemMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.barrier_hand_rails`) }}
                                    </label>
                                    <md-select v-model="features.barrier_hand_rails">
                                        <md-option v-for="type in barrierHandRailsMaterials" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>

                        <span class="md-title category-header">{{ $t("bridges.other_elements") }}</span>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.other_crossbeam`) }}
                                    </label>
                                    <md-select v-model="features.other_crossbeam">
                                        <md-option v-for="type in otherCrossbeamTypes" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.other_cables`) }}
                                    </label>
                                    <md-select v-model="features.other_cables">
                                        <md-option v-for="type in otherCablesTypes" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.other_suspenders`) }}
                                    </label>
                                    <md-select v-model="features.other_suspenders">
                                        <md-option v-for="type in otherSuspendersTypes" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.other_anchorage`) }}
                                    </label>
                                    <md-select v-model="features.other_anchorage">
                                        <md-option v-for="type in otherAnchorageTypes" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.other_pylon`) }}
                                    </label>
                                    <md-select v-model="features.other_pylon">
                                        <md-option v-for="type in otherPylonTypes" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.other_transverse_truss`) }}
                                    </label>
                                    <md-select v-model="features.other_transverse_truss">
                                        <md-option v-for="type in otherTransverseTrussTypes" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>

                        </div>


                        <span class="md-title category-header">{{ $t("bridges.signs") }}</span>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-switch v-model="features.bridge_sign" class="md-success">
                                    {{ $t("bridges.bridge_sign") }}
                                </md-switch>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-switch v-model="features.reflectors" class="md-success">
                                    {{ $t("bridges.reflectors") }}
                                </md-switch>
                            </div>
                            <div class="md-layout-item  md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.bridge_sign_comment`) }}
                                    </label>
                                    <md-input
                                            v-model="features.bridge_sign_comment"
                                            type="text"
                                    ></md-input>
                                </md-field>
                            </div>
                        </div>
                        <span class="md-title category-header">{{ $t("bridges.installations") }}</span>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-switch v-model="features.electric_cables" class="md-success">
                                    {{ $t("bridges.electric_cables") }}
                                </md-switch>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-switch v-model="features.telecom_cables" class="md-success">
                                    {{ $t("bridges.telecom_cables") }}
                                </md-switch>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-switch v-model="features.sewers" class="md-success">
                                    {{ $t("bridges.sewers") }}
                                </md-switch>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-switch v-model="features.water_mains" class="md-success">
                                    {{ $t("bridges.water_mains") }}
                                </md-switch>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-switch v-model="features.stormwater_pipes" class="md-success">
                                    {{ $t("bridges.stormwater_pipes") }}
                                </md-switch>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-switch v-model="features.spare_ducts" class="md-success">
                                    {{ $t("bridges.spare_ducts") }}
                                </md-switch>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-switch v-model="features.gas" class="md-success">
                                    {{ $t("bridges.gas") }}
                                </md-switch>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-switch v-model="features.unknown" class="md-success">
                                    {{ $t("bridges.unknown") }}
                                </md-switch>
                            </div>
                        </div>
                        <span class="md-title category-header">{{ $t("bridges.riverbed") }}</span>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.riverbed_protection`) }}
                                    </label>
                                    <md-select v-model="features.riverbed_protection"
                                               @md-closed="riverbedProtectionChanges">
                                        <md-option v-for="type in riverbedProtections" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50 md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.riverbed_slopes`) }}
                                    </label>
                                    <md-select v-model="features.riverbed_slopes">
                                        <md-option v-for="type in riverbedSlopes" :value="type" :key="type">{{
                                            $t(`bridges.${type}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                                <md-field>
                                    <label>
                                        {{ $t(`bridges.riverbed_alignment`) }}
                                    </label>
                                    <md-select v-model="features.riverbed_alignment">
                                        <md-option v-for="type in riverbedAlignments" :value="type.name" :key="type.name">{{
                                            $t(`bridges.riverbed_alignment_${type.name}`)
                                            }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                        </div>
                        <span class="md-title category-header"></span>
                        <md-field>
                            <label>
                                {{ $t(`bridges.fondation`) }}
                            </label>
                            <md-select
                                    v-model="features.foundation">
                                <md-option v-for="type in foundations" :value="type" :key="type">{{
                                    $t(`bridges.${type}`)
                                    }}
                                </md-option>
                            </md-select>
                        </md-field>
                        <md-field>
                            <label> {{ $t(`bridges.comments_and_notes`) }}</label>
                            <md-textarea v-model="features.comments"></md-textarea>
                        </md-field>
                    </md-card-content>
                </md-card>
            </div>
            <md-card-actions md-alignment="left">
                <md-button
                        native-type="submit"
                        @click.native.prevent="validate"
                        class="md-success"
                        v-if="mode!=='read'"
                >{{ $t("button.save") }}
                </md-button
                >
                <md-button
                        @click.native.prevent="back"
                        class="md-default"
                >{{ $t("button.back") }}
                </md-button
                >
            </md-card-actions>
        </form>
    </div>
</template>
<script>
import Swal from "sweetalert2"
import InspectionLink from "@/components/Inspection-Link"

export default {
    name: "",
    components: {InspectionLink},
    props: {
        mode: {type: String}
    },
    data() {
        return {
            bid: this.$route.params.bid,
            materialsData: {},
            features: {
                inspection_date: new Date(),
                bridge_id: "",
                type_of_bridge: "",
                structural_form: "",
                deck_type: "",
                deck_material: "",
                edge_beam: "",
                main_girder_type: "",
                main_girder_material: "",
                cross_beam_type: "",
                cross_beam_material: "",
                construction_joints: "",
                abutment_type: "",
                abutment_material: "",
                abutment_cap: "",
                wing_walls_type: "",
                wing_walls_material: "",
                pier: "",
                pier_material: "",
                pier_cap: "",
                bearing1: "",
                bearing2: "",
                bearing_seat: "",
                expansion_joints: "",
                outlet_pipes: "",
                downsprout_pipes: "",
                deck_drains: "",
                pipe: "",
                run_on_slab_cover: "",
                run_on_slab_kerbs: "",
                run_on_slab_vehicle_restraint_system: "",
                run_on_slab_drainage_gully: "",
                pavement_road: "",
                pavement_sidewalk: "",
                barrier_vehicle_restraint_system: "",
                barrier_hand_rails: "",
                bridge_sign: "",
                bridge_sign_comment: "",
                reflectors: "",
                electric_cables: "",
                telecom_cables: "",
                sewers: "",
                water_mains: "",
                stormwater_pipes: "",
                spare_ducts: "",
                gas: "",
                unknown: "",
                riverbed_protection: "",
                riverbed_slopes: "",
                riverbed_alignment: "",
                foundation: "",
                comments: "",
                //design_drawings_available: "",

                description: ""
            },

            bridgeTypes: ["girder", "frame", "arch", "truss", "cantilever", "suspension", "cableStayed", "culvert", "tunnel"],
            bridgeStructuralForms: ["simplySupported", "continuous", "partiallyContinuous", "cantileverAndSuspendedSpan",
                "arch", "boxTubularRigidFrame", "integralSemiSpanFrameBridge", "other"],


            // mainGirgerTypes: [
            //     {name: "bailey"},
            //     {name: "boxGirder"},
            //     {name: "beam"},
            //     {name: "truss"},
            //     {name: "slab"},
            //     {name: "arch"},
            //     {name: "culvert"},
            //     {name: "frame"}
            // ],
            // crossBeamTypes: [
            //     {name: "none"},
            //     {name: "beam"},
            //     {name: "truss"}],

            riverbedAlignments: [
                {name: "straight"},
                {name: "meandering"},
                {name: "curved"},
                {name: "skewed"}
            ],

            otherCrossbeamTypes: [
                "steel",
                "concrete_cast",
                "concrete_precast",
                "timber_normal",
            ],
            otherCablesTypes: ["steel", "other"],
            otherSuspendersTypes: ["steel", "other"],
            otherAnchorageTypes: ["steel", "other"],
            otherPylonTypes: ["steel", "concrete", "other"],
            otherTransverseTrussTypes: ["steel", "timber_normal"],

        }
    },
    filters: {
        UpCase: function (value) {
            return value.toUpperCase()
        }
    },
    methods: {
        async validate() {
            const alert = {
                type: 'success',
                text: this.$t(`bridge.record_was_added`),
                footer: ''
            }

            const {bridge_id, created_at, updated_at, ...data} = this.features

            try {
                await this.$store.dispatch("ADD_BRIDGE_INVENTORY_INSPECTION", {bridge_id: this.bid, data: data})
                Swal.fire(alert)
            } catch (err) {
                alert.type = 'error'
                alert.text = this.$t(`bridge.record_was_not_added`)
                Swal.fire(alert)
                throw err
            }


        },
        back() {
            this.$router.go(-1)
        },
        getMaterialsList(ids) {
            return this.materialTypes.filter(material => ids.includes(material.id)).map(item => item.name)
        },
        deckTypeChanged() {
            this.features.deck_material = ""
        },
        mainGirderTypeChanged() {
            this.features.main_girder_material = ""
        },
        crossBeamTypeChanged() {
            this.features.cross_beam_material = ""
        },
        abutmentsTypeChanged() {

            this.features.abutment_material = ""
        },
        wingWallChanged() {
            this.features.wing_walls_material = ""
        },
        piersChanged() {
            this.features.pier_material = ""
        },
        riverbedProtectionChanges() {
            this.features.riverbed_slopes = ""
        },
        checkAndGetElementTypes(element) {
            if (!this.materialsData[element]) {
                return []
            }
            const types = {}
            const elementContent = this.materialsData[element]
            if (elementContent && Array.isArray(elementContent)) {
                elementContent.forEach(content => {
                    types[content.type] = true
                })
            }
            return Object.keys(types).sort()
        },
        checkAndGetElementMaterials(element, type = "") {
            if (!this.materialsData[element]) {
                return []
            }
            const materials = {}
            const elementList = type !== "" ? this.materialsData[element].filter(item => {
                return item.type === type
            }) : this.materialsData[element]

            elementList.forEach(item => {
                materials[item.material] = true
            })

            return Object.keys(materials).sort()
        }

    },
    computed: {
        deckTypes() {
            return this.checkAndGetElementTypes('deck')
        },
        mainGirgerTypes() {
            return this.checkAndGetElementTypes('main_girder')
        },
        crossBeamTypes() {
            return [
                "none",
                "beam",
                "truss"
            ]
        },
        abutmentsTypes() {
            return this.checkAndGetElementTypes('abutment')
            // return [
            //     {name: "none"},
            //     {name: "cantilever"},
            //     {name: "gravity"},
            //     {name: "pile_stub_abutment)"},
            //     {name: "bank_seated_stub_abutment)"},
            //     {name: "wall_and_counterfort"},
            //     {name: "open_abutment"},
            //     {name: "full_height_integral"},
            //     {name: "integral_with_pile_foundation"},
            //     {name: "integral_with_spread_footing"}
            // ]
        },
        wingWallsTypes() {
            return this.checkAndGetElementTypes('wing_walls')
            // return [
            // {name: "part_of_abutment"},
            // {name: "free_standing"},
            // {name: "strengthened_reinforced"},
            // {name: "splayed"}]
        },
        piersTypes() {
            return this.checkAndGetElementTypes('pier')
            // return [
            // {name: "none"},
            // {name: "solidWall"},
            // {name: "multipleColumn"},
            // {name: "singleColumn"},
            // {name: "gravity"},
            // {name: "columnWall"},
            // {name: "pile"},
            // {name: "cantilever"},
            // {name: "integral"}]
        },

        runOnSlabCovers() {
            return this.checkAndGetElementMaterials('run_on_slab')
        },
        pavements_overlay_road() {
            return this.checkAndGetElementMaterials('pavement_overlay_road')
        },
        pavements_overlay_sidewalk() {
            return this.checkAndGetElementMaterials('pavement_overlay_sidewalk')
        },
        riverbedProtections() {
            return this.checkAndGetElementMaterials('riverbed_slopes', 'protection')
        },
        riverbedSlopes() {
            return this.checkAndGetElementMaterials('riverbed_slopes', 'training')
        },

        foundations() {
            return this.checkAndGetElementTypes('foundations')
        },
        bearings() {
            return this.checkAndGetElementTypes('bearings')
        },
        constructionJointsList() {
            return this.checkAndGetElementMaterials('construction_joints')
        },
        expansionJoints() {
            return this.checkAndGetElementTypes('expansion_joints')
        },
        deckTypeMaterials() {
            return this.checkAndGetElementMaterials('deck', this.features.deck_type)
        },
        edgeBeamMaterials() {
            return this.checkAndGetElementMaterials('deck_edge_beam')
        },
        mainGirderMaterials() {
            return this.checkAndGetElementMaterials('main_girder', this.features.main_girder_type)
        },
        crossBeamMaterials() {
            return this.checkAndGetElementMaterials('cross_beam')
        },
        abutmentsMaterials() {
            return this.checkAndGetElementMaterials('abutment', this.features.abutment_type)
        },
        abutmentCapMaterials() {
            return this.checkAndGetElementMaterials('abutment_cap')
        },
        wingWallsMaterials() {
            return this.checkAndGetElementMaterials('wing_walls', this.features.wing_walls_type)
        },
        piersMaterials() {
            return this.checkAndGetElementMaterials('pier', this.features.pier)
        },
        piersCapMaterials() {
            return this.checkAndGetElementMaterials('pier_cap')
        },
        outletPipeMaterials() {
            return this.checkAndGetElementMaterials('outlet_pipes')
        },
        downsproutPipeMaterials() {
            return this.checkAndGetElementMaterials('downsprout_pipe')
        },
        deckDrainMaterials() {
            return this.checkAndGetElementMaterials('deck_drains')
        },
        pipeMaterials() {
            return this.checkAndGetElementMaterials('outlet_pipes')
        },
        runOnSlabKerbsMaterials() {
            return this.checkAndGetElementMaterials('run_on_slab_kerbs')
        },
        runOnSlabVehicleRestraintSystemMaterials() {
            return this.checkAndGetElementMaterials('run_on_slab_vehicle_restraint_system')
        },
        runOnSlabDrainageGullyMaterials() {
            return this.checkAndGetElementMaterials('run_on_slab_drainage_gully')
        },
        barrierVehicleRestraintSystemMaterials() {
            return this.checkAndGetElementMaterials('vehicle_restraint_system')
        },
        barrierHandRailsMaterials() {
            return this.checkAndGetElementMaterials('handrails')
        }
    },
    async created() {
        try {
            const inventData = await this.$store.dispatch("LOAD_BRIDGE_INVENTORY_DATA", this.bid)
            this.materialsData = await this.$store.dispatch("LOAD_BRIDGE_ELEMENT_MATERIALS")
            console.log({inventData})
            this.features = {...this.features, ...inventData}
            this.features.inspection_date = inventData['inspection_data'] ? this.features.inspection_date.substring(0, 10) : new Date()
        } catch (err) {
            throw err
        }
    }
}
</script>
<style lang="scss" scoped>
.md-switch {
  display: flex;
}

.card-title {
  font-size: 17px;
}

.md-card .md-card-actions {
  border: none;
}

.category-header {
  display: block;
  margin-top: 30px;
}
</style>
